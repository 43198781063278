

<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { financial } from '@/api/misc'
import { initAgentApi } from "@/api/agent"
import {getArApApi} from '@/api/cash'
import {searchFee} from '@/api/misc'
import Choices from "choices.js";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {

  page: {
    title: "AR ",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },


  components: {
    Layout,
    PageHeader,
    flatPickr
  },


  data() {

    return {
      title: "Agent Bill Detail",
      items: [
        {
          text: "Agent Bill",
          href: "/",
        },
      ],
      current_fee_code: '',
      fee_amount : 0,
      fee_gst    : 0,
      post_date  : '',
      description: '',

      bill : {
        agent : {}
      },
      fee_choice : {
        is_init : false
      },
      fee_list         : [],
      removed_fee_list :[],
      trans_list       : [],
      current_transaction : {},
    }

  },
  methods: {
    f(n, d = 2) {
      return financial(n, d)
    },

    onSubmitChange() {
      let data = {
        invoice         : this.bill.invoice_number,
        new_fee_list    : this.bill.items.filter(e => !e.id),
        delete_fee_list : this.removed_fee_list,

      }


      initAgentApi().adjust_agent_bill(data).then(res => {
        if (res.errCode == 0) {
          this.$router.push({name : 'ar-agent-bill'})
          this.$alertify.message(" adjusted ");
        } else {
          this.$alertify.error("Failed to adjust agent bill " + res.errCode);
        }
      })
    },

    
    onSearchedFee(e) {
        searchFee({"search_str" : e.detail.value}).then((res) =>{
            this.fee_list = []
            res.data.map((f) =>{
                this.fee_list.push({
                    label : `${f.NAME} (${f.accounting_gl})`,
                    value : f.CODE,
                    fee : f
                })
            })
            this.fee_choice.clearChoices()
            this.fee_choice.setChoices(this.fee_list)
            this.fee_choice.showDropdown()
        })
        
    },

    onAddFee() {

      let fee = this.fee_list.find((f) => f.value == this.current_fee_code)

      this.bill.items.push({
        fee_code  : fee.fee.code,
        fee_name  : fee.fee.name,
        fee       : financial(Number(this.fee_amount), 2),
        fee_tax   : financial(Number(this.fee_gst), 2),
        post_date : this.post_date,
        description : this.description,
        summary : this.description
      })
      
      this.bill.sub_amount += Number(this.fee_amount)
      this.bill.sub_amount_gst += Number(this.fee_gst)
     
    },

    onRemoveFee(fee, idx) {
      this.bill.items.splice(idx, 1)
      if (fee.id) {
        this.removed_fee_list.push(fee)
      }

      this.bill.sub_amount -= Number(fee.fee)
      this.bill.sub_amount_gst -= Number(fee.fee_tax)
    },

    showChargeDialog() {
      
     
      this.$bvModal.show('modal-fee-charge')
    
     // this.fee_choice.is_init = true
    },

    do_reverse() {
      let data = {
        bill_id        : this.bill.id,
        transaction_id : this.current_transaction.id,
        post_date      : this.post_date,
        remarks        : this.description
      }
      getArApApi().reverse_agent_bill_transaction(data).then(res => {
       
        if (res.errCode == 0) {
          this.$bvModal.hide('reverse-trans-dialog')
          this.$alertify.message('Reversed')
        } else {
          this.$alertify.message('Reverse Failed '+ res.errCode)
        }
      })
      
    },

    show_reverse_dialog(transaction) {
      this.current_transaction = transaction
      this.$bvModal.show('reverse-trans-dialog')
    }

  },

  created() {

  },

  mounted() {
    
    
    let invoice_number = this.$route.query.invoice_number
   
    initAgentApi().query_bill({invoice : invoice_number }).then((res) => {
        if (res.errCode == 0) {
          this.bill = res.data
          this.trans_list = this.bill.transactions  //.filter(e=> e.status =='COMPLETED')
        }
    })


    this.fee_choice = new Choices('#choices-fee-input')
   
  },

  watch: {
    fee_amount(new_val) {
      this.fee_gst = this.f(Number(new_val) * 0.05, 2)
    }
  }



};
</script>


<template>
  
  <Layout>
    <PageHeader :title="title" :items="items" />


    <b-modal centered id="modal-fee-charge" title="Fee Charge" size="lg" hide-footer static>
      <div class="row mt-4">
          <div class="col-md-6">
              <div class="mb-3">
                  <label  class="form-label">Fee Type</label>
                  <select class="form-control" v-model="current_fee_code" name="choices-fee-input"
                      @search="onSearchedFee" id="choices-fee-input">
                  </select>
              </div>
          </div>
          <div class="col-md-2">
              <div class="mb-3">
                  <label f class="form-label">Amount</label>
                  <input type="text" v-model="fee_amount" class="form-control" placeholder="$" />
              </div>
          </div>
          <div class="col-md-2">
              <div class="mb-3">
                  <label f class="form-label">Gst</label>
                  <input type="text" v-model="fee_gst" class="form-control" placeholder="$" />
              </div>
          </div>

        

      </div>
      <!-- end row-->

      <div class="row mt-4">
        <div class="col-md-3">
          <div class="mb-3">
              <label for="validationCustom03">Post Date</label>
              <flat-pickr v-model="post_date"  placeholder="Select a date" class="form-control"  ></flat-pickr>
              
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
              <label for="validationCustom03">Description</label>
              <input type="text" v-model="description" class="form-control" placeholder="" />
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-md-5"></div>
        <div class="col-md-2">
            <div class="mb-3 mt-4">
                <b-button variant="secondary" type="button" @click="onAddFee">Add Entry</b-button>
            </div>
        </div>
      </div>

    </b-modal>


    <b-modal centered id="reverse-trans-dialog" title="Fee Charge" size="lg" hide-footer static>
      <div class="row mt-4">
        <div class="col-md-3">
          <div class="mb-3">
              <label for="validationCustom03">Post Date</label>
              <flat-pickr v-model="post_date"  placeholder="Select a date" class="form-control"  ></flat-pickr>
              
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
              <label for="validationCustom03">Description</label>
              <input type="text" v-model="description" class="form-control" placeholder="" />
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-md-5"></div>
        <div class="col-md-2">
            <div class="mb-3 mt-4">
                <b-button variant="secondary" type="button" @click="do_reverse">Submit</b-button>
            </div>
        </div>
      </div>
    </b-modal>

      <div class="row">
        <div class="col-lg-12 mb-3">
        
          <div class="card">
            <div class="card-body">

              <div class="invoice-title">
                <div class="text-muted">
                  <h4 class="float-end font-size-15">

                   
                    <span class="badge bg-success font-size-12 ms-2" v-if="bill.is_finish_flag == 1">Paid</span>
                    <span class="badge bg-success font-size-12 ms-2" v-else>Pending Charge</span>
                  
                  </h4>

                  <p class="mb-1">Agent:{{ bill.agent_name }}</p>
                  <p class="mb-1">Bill Statement Month:{{ bill.bill_month }}</p>
                  <p class="mb-1">Invoice #:{{ bill.invoice_number }}</p>
                  <p class="mb-1">{{ bill.agent.address }}</p>
                  <p class="mb-1"><i class="uil uil-envelope-alt me-1"></i> {{ bill.agent.email }}</p>
                  <p><i class="uil uil-phone me-1"></i>{{ bill.agent.phone }}</p>
                
                </div>
              </div>

              <hr class="my-4" />
              <div class="table-responsive">
                <div class="table align-middle table-nowrap">
                  <b-table
                    :items="trans_list"
                    :fields="['Transaction', 'Amount','Date','Status','Remarks','Action']"
                    responsive="sm"
                    :per-page="30"
                    class="table-check datatables"
                   
                  >
                    <template  #cell(Transaction)="data">
                      {{ data.item.bearer_number }}
                    </template>
                  
                    <template #cell(Amount)="data">
                      ${{ (data.item.amount).toLocaleString()}}
                    </template>  
                    <template #cell(Date)="data"> 
                      {{ data.item.post_date }}
                    </template>

                    <template #cell(Status)="data"> 
                      {{ data.item.status }}
                    </template>

                    <template #cell(Remarks)="data"> 
                      {{ data.item.remarks?data.item.remarks.substring(0, 40):'' }}
                    </template>
                  
                    <template #cell(Action)="data"> 
                      <b-button variant="danger"     @click="show_reverse_dialog(data.item)" v-if="data.item.status == 'COMPLETED'">Reverse</b-button>
                    </template>
                  </b-table>
                </div>
              </div>

              <hr class="my-4" />
              <div class="py-2">
                <h5 class="font-size-15">Bill Summary</h5>

                <div class="table-responsive">
                  <table class="table align-middle table-nowrap table-centered mb-0">
                    <thead>
                      <tr>
                        <th style="width: 100px;">No.</th>
                        <th>Fee Code</th>
                        <th>Fee Name</th>
                        <th>Note</th>
                        <th>Amount</th>
                        <th>GST</th>
                        <th class="text-end" style="width: 120px;">Total</th>
                        <th class="text-end" style="width: 40px;"></th>
                      </tr>
                    </thead>
                    <!-- end thead -->
                    <tbody>
                      <tr v-for="(item, idx) in bill.items" :key="'bill_item_'+idx">
                        <th scope="row">{{ idx + 1 }}</th>
                        <td>
                          <div>
                            <h5 class="text-truncate font-size-14 mb-1">{{ item.fee_code }} </h5>
                            <p class="text-muted mb-0"></p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <h5 class="text-truncate font-size-14 mb-1">{{ item.fee_name }} </h5>
                            <p class="text-muted mb-0"></p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <h5 class="text-truncate font-size-14 mb-1">
                              {{ item.summary?item.summary :'' +' ' + `${item.regbv? ('Inv:'+item.regbv.invoice+' Address:'+ item.regbv.address+' Completed Date:'+item.regbv.completion_date):''}` }}
                             </h5>
                            <p class="text-muted mb-0"></p>
                          </div>
                        </td>
                        <td>${{ f(item.fee).toLocaleString() }}</td>
                        <td>${{ f(item.fee_tax).toLocaleString() }}</td>
                        <td class="text-end">${{ f(item.fee + item.fee_tax).toLocaleString() }}</td>

                        <td class="text-end">
                          <div class="row icon-demo-content-10 align-right" v-if="bill.is_finish_flag == 0">
                            <div class="col-xl-3 col-lg-4 col-sm-6"  @click="onRemoveFee(item, idx)">
                              <i class="uil-times font-size-4"></i></div>
                          </div>
                        </td>

                      </tr>
                      <!-- end tr -->

                      <tr>
                        <th scope="row" colspan="6" class="text-end">
                          Sub Total
                        </th>
                        <td class="text-end">${{ f(bill.sub_amount +bill.sub_amount_gst ).toLocaleString() }}</td>
                      </tr>
                      <!-- end tr -->
                      <tr>
                        <th scope="row" colspan="6" class="border-0 text-end">
                          Credit :
                        </th>
                        <td class="border-0 text-end">- ${{ f(bill.credit).toLocaleString() }}</td>
                      </tr>
                      <!-- end tr -->
                      <tr>
                        <th scope="row" colspan="6" class="border-0 text-end">
                          Total
                        </th>
                        <td class="border-0 text-end">
                          <h4 class="m-0 fw-semibold">${{  f(bill.sub_amount +bill.sub_amount_gst +bill.credit).toLocaleString() }}</h4>
                        </td>
                      </tr>
                      <!-- end tr -->
                    </tbody>
                    <!-- end tbody -->
                  </table>
                  <!-- end table -->
                </div>
                <!-- end table responsive -->
               
              </div>
            </div>
          </div>
          <!-- end card for-->

          <div class="d-print-none mt-4 mb-3" :class="$route.query.adjust == true? '':'d-none'">
            <div class="float-end gap-3 d-flex">
              <b-button variant="danger"  @click="showChargeDialog"  v-if="bill.is_finish_flag == 0">Charge More Fee </b-button>
              <b-button variant="primary" @click="$router.push({name : 'ar-agent-bill'})"  v-if="bill.is_finish_flag == 0">Cancel </b-button>
             
              <b-button variant="primary">Export PDF </b-button>

              <b-button variant="danger" @click="onSubmitChange" v-if="bill.is_finish_flag == 0">Submit</b-button>
            </div>
          </div>

          <div class="row mb-3"></div>

        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </Layout>
  

</template>