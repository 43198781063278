
import request from '@/utils/request';




class AgentApi {
   

    delete_fee_record = (data) => {
        return request({
            url: '/adm/agent/fee/delete_fee_record',
            method: 'post',
            data
        });	
    }
    

    query_bill = (data) => {
        return request({
            url: '/adm/brokerage/agent/agent/agent_bill_detail',
            method: 'post',
            data
        });	
    }

    adjust_agent_bill = (data) => {
        return request({
            url: '/adm/brokerage/agent/agent/modify_agent_bill',
            method: 'post',
            data
        });	
        
    }

    query_hold_commission = (data) => {
        return request({
            url: '/adm/brokerage/agent/agent/agent_hold_commission_query',
            method: 'post',
            data
        });	
        
    }

    query_all_unpaid_bills = (data) => {
        return request({
            url: '/adm/brokerage/agent/agent/query_agent_unpaied_bills',
            method: 'post',
            data
        });	
        
    }


    terminate_agent = (data) => {
        return request({
            url: '/adm/brokerage/agent/agent/terminate_agent',
            method: 'post',
            data
        });	
        
    }



    
}



let agentApi = null;

/**
 * Initilize the backend
 * @param {*} config 
 */
const initAgentApi = () => {


    if (!agentApi) {
        agentApi = new AgentApi();
    }

    return agentApi;
}



export { initAgentApi };


